import React, { useContext, useState } from "react";
import SiteConstant from "../../../constants/Site";
import "../EditorSiteForm.scss";
import LocaleContext from "../../../context/LocaleContext";
import EditorSiteForm from "../EditorSiteForm";
import { INDIV, FULL_SELLOUT, ACC } from "./SiteTypes";
import { Step0 } from "./Step0";
import { toogleNotification } from "../../../store/notifications/NotificationsStore";
import { POP_UPS } from "../../../constants/Notifications";
import { MAILTYPES } from "../../../constants/MailTypes";
import { setQueryParams } from "../../../helpers/Router";

/**
 *
 * If user is Consumer , they can create :
 * 	Individual consumer site
 * 	Full Sell Out site (as consumer)
 *
 *
 */
export function CONSUMERStep0(props) {
  const { cancelEditor } = props;
  const context = useContext(LocaleContext);
  const { i18n } = context;
  const POSSIBLE_CHOICES = [
    {
      name: i18n.t("selfConsumptionSite"),
      description: i18n.t("captions.selfConsumptionSite"),
      img: INDIV,
      action() {
        setRenderEditor(SiteConstant.type.CONSUMER);
      },
    },
    {
      name: i18n.t("fullSellOutSite"),
      description: i18n.t("captions.fullSellOutSite"),
      img: FULL_SELLOUT,
      action() {
        setIsFullSellOut(true);
        setRenderEditor(SiteConstant.type.PRODUCER);
      },
    },
    {
      name: i18n.t("consumer_plusSite"),
      description: i18n.t("captions.consumer_plusSite"),
      buttonTrigger: () => {
        setQueryParams(MAILTYPES.CONTACT);
        toogleNotification(POP_UPS.SEND_MAIL);
      },
      img: ACC,
      action() {
        //TRIGGER NO CAN DO POPUP because CONSUMER can't do ACC
        toogleNotification(POP_UPS.GET_ACC);
      },
    },
  ];
  const handleRemoveEditor = () => {
    setRenderEditor(null);
  };
  const [renderEditor, setRenderEditor] = useState(null);
  const [isFullSellOut, setIsFullSellOut] = useState(false);

  if (!renderEditor)
    return <Step0 choices={POSSIBLE_CHOICES} cancelEditor={cancelEditor} />;
  return (
    <EditorSiteForm
      type={renderEditor}
      {...props}
      isFullSellOut={isFullSellOut}
      handleRemoveEditor={handleRemoveEditor}
    />
  );
}
