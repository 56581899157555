import { useRef, useEffect } from "react";
import SiteConstants from "../constants/Site";
import { ENEDIS_ERRORS } from "../constants/Labels/EnedisError";
import { doesSiteHasInitialConso } from "../helpers/Validators";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isEmail from "validator/lib/isEmail";
import _ from "lodash";
dayjs.extend(customParseFormat);
export function capitalize(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
}

export const CRUD = {
  ADD: "ADD",
  CREATE: "CREATE",
  READ: "READ",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export function getPDFElementTranslation(e, site, isFullSellOut) {
  const showAsGains = !doesSiteHasInitialConso(site);
  switch (e) {
    case "TotalEnergy":
      return "totalEnergyUsed";
    case "TotalCost":
      return "totalCost";
    case "UnitCost":
      return "unitCost";
    case "CostPerMonth":
      return "costPerMonth";
    case "EnergyBalance":
      return "energyBalance";
    case "LoadCurve":
      return "powerDistribution";
    case "LoadCurve_SOC":
      return "SOC Batterie";
    case "LoadCurve_H2":
      return "Stockage H2";
    case "LoadCurve_Stations":
      return "stations";
    case "LoadCurve_FCR_SPOT":
      return "FCR_SPOT";
    case "DailyProfile":
      return "dailyProfile";
    case "EnergeticMix":
      return "energeticMix";
    case "MonotoneOfPower":
      return "monotonic";
    case "Histogram":
      return "histogram";
    case "Van":
    case "VanFromPopUp":
      return "npv";
    case "LineBalanceSheet":
    case "LineBalanceSheetFromPopUp":
      return showAsGains
        ? "gainsProjection"
        : site?.isGainsMode
        ? "gainsLineBalanceSheetChart"
        : "lineBalanceSheetChart";
    case "ScenarioTable":
    case "ScenarioTableFromPopUp":
      return "financialSynthesis";
    case "FreeCashFlowFromPopUp":
      return "Free Cashflow";
    case "TechSummary":
      return "techSummary";
    case "Objectives":
      return "objectives";
    case "YearlyBill":
      return "yearlyBill";
    case "Opex":
      return showAsGains || isFullSellOut ? "supplierOpex" : "opex";
    case "OptimizedSubP":
      return "optimizedSubP";
    case "ConsumerEnergyBalance":
      return "consumerEnergyBalance";
    case "DistributionKey":
      return "distributionKey";

    default:
      return e;
  }
}

export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
}

export async function buildPowersFileExcelData(file) {
  const buildRows = async () => {
    const rowsArray = file.map((row) =>
      Object.values(row).map((cell) => ({
        value: cell,
        style: {
          numFmt: "0",
        },
      }))
    );
    return rowsArray;
  };

  const rows = await buildRows();
  const set = [
    {
      columns: Object.keys(file[0]).map((field) => ({
        title: field.toString(),
      })),
      data: rows,
    },
  ];
  return set;
}

export function clearSessionStorage() {
  const items = [
    "TotalEnergy",
    "ppaGraph",
    "Opex",
    "TechSummary",
    "AutoProd",
    "Van",
    "OptimizedSubP",
    "LineBalanceSheet",
    "Backup",
    "FreeCashFlow",
    "EnergyBalance",
    "UnitCost",
    "CostPerMonth",
    "TotalCost",
    "MonotoneOfPower",
    "YearlyBill",
    "DailyProfile",
    "Histogram",
    "CO2",
    "LoadCurve",
    "PV_EnergeticMix",
    "PV2_EnergeticMix",
    "WT_EnergeticMix",
    "Grid_EnergeticMix",
    "ConsumerEnergyBalance",
    // "ScenarioTable",
  ];
  items.forEach((i) => sessionStorage.removeItem(i));
}

function escapeRegExpChars(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function createRegExpFromSearchWords(searchPhrase) {
  let normalizedSearchPhrase = "";

  try {
    //remove all accents/diacritics (é -> e, à -> a ...)
    normalizedSearchPhrase = searchPhrase
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  } catch (err) {
    console.error(err);
  }

  let regExpString = normalizedSearchPhrase
    .split(/\s+/)
    .map((word) => `(${escapeRegExpChars(word)})`)
    .join(".*");
  let regExp = new RegExp("");

  try {
    /* RegExp("(word1).*(word2).*(word3)", "gi"); */
    regExp = new RegExp(regExpString, "i");
  } catch (err) {
    console.error(err);
  }

  return regExp;
}

export function pause(ms) {
  return new Promise((res) => setTimeout(res, ms));
}

export const kWh2MWh = (value) => value / 1000;
export const MWh2kWh = (value) => value * 1000;
export const Wh2kWh = (value) => value / 1000;
export const Wh2MWh = (value) => value / 1000000;

/**
 * 
 * Snippet to paste in class component
componentDidUpdate(prevProps, prevState) {
  Object.entries(this.props).forEach(([key, val]) =>
    prevProps[key] !== val && console.log(`Prop '${key}' changed`)
  );
  if (this.state) {
    Object.entries(this.state).forEach(([key, val]) =>
      prevState[key] !== val && console.log(`State '${key}' changed`)
    );
  }
}
 */

export const formatNumber = (number, decimals = 1) => {
  return Number(number).toFixed(decimals);
};

// Trouve la clé avec la valeur maximale dans un objet, en ignorant certaines clés.
export const findMaxKey = (objet, clesAIgnore) => {
  const filteredKeys = Object.keys(objet).filter(
    (key) => !clesAIgnore.includes(key)
  );

  if (filteredKeys.length === 0) {
    return null;
  }

  return filteredKeys.reduce((a, b) => (objet[a] > objet[b] ? a : b));
};
export const getTCSPEFromSubscribedPowers = (powers, TCSPE = false) => {
  const filteredPowers = powers.map(({ startDate, endDate, id, ...values }) =>
    Object.values(values)
  );
  // test if TCSPE is in the list of TCSPE values to avoid returning and edit the value
  if (TCSPE && _.includes(SiteConstants.CSPE, TCSPE) === false) {
    return TCSPE;
  }

  const maxSubP = parseFloat(Math.max(...filteredPowers[0]));
  if (maxSubP <= 36) {
    return SiteConstants.CSPE[SiteConstants.subscribedPowersRanges["<36kVA"]];
  } else if (maxSubP > 36 && maxSubP <= 250) {
    return SiteConstants.CSPE[SiteConstants.subscribedPowersRanges["<=250kVA"]];
  } else if (maxSubP > 250) {
    return SiteConstants.CSPE[SiteConstants.subscribedPowersRanges[">250kVA"]];
  }
};

export const cleanString = (str) =>
  str.replace(/[&\/\\#,+()$~%.'":*!?<>{}]/g, "_").trim();

export function formatErrorStatus(err) {
  switch (err) {
    case 503:
      return "Erreur de récupération des données météorologiques : le service est surchargé";
    case 500:
    case "SGT500":
    case "ILE500":
      return "Erreur Enedis indépendante de notre volonté. Réessayez d’ici quelques minutes.";
    case 404:
      return "Site introuvable";
    case 400:
      return "Erreur de format du numéro de compteur : le numéro de compteur soit comporter 14 chiffres";
    case "BASIC_LIMIT":
      return "Votre licence ne vous permet pas d'analyser un site ayant un domaine de tension supérieur à 36 kVA";
    case "SGT589":
      return "La demande ne peut pas aboutir car le compteur n’est actuellement pas téléopérable";
    case "SGT422":
      return "La situation du point ne permet pas de répondre à la demande : entrez en contact avec votre fournisseur qui doit corriger ce genre de problème (ou directement Enedis)";
    case "SGT4N1":
      return "La consultation de données de mesures n’est autorisée que sur la période sur laquelle le client est détenteur du point";
    case "Erreur : Le point a été résilié":
      return err;
    case "Demande non recevable : point inexistant":
      return err;
    case "PRODUCER PRM":
      return "Erreur : Ce compteur est un compteur de producteur d'énergie.";
    default:
      return ENEDIS_ERRORS[err] ?? err;
  }
}

export function extractEnedisErrorCode(errorString) {
  return errorString?.split("|").map((str) => str.trim())[0] || "Erreur";
}
export const checkEmailFormat = (email) => {
  return isEmail(email);
};

export const filterEmptyComments = (node) => {
  const exclusionClasses = [
    "empty",
    "highcharts-credits",
    "tooltip",
    "toggler",
    "switch",
  ];
  return !exclusionClasses.some((classname) =>
    node.classList?.contains(classname)
  );
};

export const arePricesExpressedAsEuro = ({
  endDate,
  startDate,
  id,
  ...prices
}) => {
  return Math.max(...Object.values(prices).map((v) => +v)) >= 1;
};

export const revertNumber = (number, revert = true) =>
  revert ? -number : number;
export const simplifyString = (string) => {
  if (typeof string === "string") {
    return string
      .toLowerCase()
      .split(" ")
      .filter((char) => char !== "")
      .join(" ")
      .replace(/[àáâãäå]/g, "a")
      .replace(/[æ]/g, "ae")
      .replace(/[ç]/g, "c")
      .replace(/[èéêë]/g, "e")
      .replace(/[ìíîï]/g, "i")
      .replace(/[ðòóôõöø]/g, "o")
      .replace(/[ñ]/g, "n")
      .replace(/[œ]/g, "oe")
      .replace(/[š]/g, "s")
      .replace(/[Þ]/g, "p")
      .replace(/[ùúûü]/g, "u")
      .replace(/[ýÿ]/g, "y");
  }
  throw new Error("simplifyString: Input is not a string");
};

export const getRandomColor = () =>
  `#${Math.floor(Math.random() * 0x1000000)
    .toString(16)
    .padStart(6, 0)}`;

export const decomposePriceBuilderSlots = (site) => {
  const { restOfTheYearTarifs, tarifs } = site;
  let subscribedPowers,
    subscriptionRates,
    energyRates = [];

  subscribedPowers = [
    ...tarifs.map((t) => ({ label: t.name, value: t.subscribedPower })),
    { label: "Reste de l'année", value: +restOfTheYearTarifs.subscribedPower },
  ];
  subscriptionRates = [
    ...tarifs.map((t) => ({ label: t.name, value: t.subscriptionRate })),
    { label: "Reste de l'année", value: +restOfTheYearTarifs.subscriptionRate },
  ];
  energyRates = [
    ...tarifs.map((t) => ({ label: t.name, value: t.energyRate })),
    { label: "Reste de l'année", value: +restOfTheYearTarifs.energyRates },
  ];
  return { subscribedPowers, subscriptionRates, energyRates };
};

//For regular sites
export const getSubscribedPowersFromVoltageRange = (voltageRange, subP) => {
  //subP are always in the same order like below;
  const powerLabels = ["PTE", "HPH", "HCH", "HPB", "HCB"];
  let result = [];
  result = powerLabels.map((label, i) => ({ label, value: subP[i] }));
  switch (voltageRange) {
    case "BT<36 kVA":
      result = [{ label: "Base", value: subP[0] }];
      break;
    case "BT>36 kVA":
      result.shift();
      break;
    default:
      break;
  }

  return result;
};

//For TarifBuilder sites
export const getTBSubscribedPowersFromVoltageRange = (subP, site) => {
  let result = [];
  result = [
    ...site.tarifs.map((tarif, index) => ({
      label: tarif.name,
      value: subP[index],
    })),
    { label: "Reste de l'année", value: subP[subP.length - 1] },
  ];

  return result;
};

export const countUnvalidArrays = (...arrays) => {
  let invalid = 0;
  arrays.map((array) => {
    if (array?.length === 0) invalid++;
  });
  return invalid;
};

export const countUnvalidFields = (...values) => {
  let invalid = 0;
  values.map((value) => {
    if (Array.isArray(value)) {
      value.map((v) => {
        Object.entries(v).map(([k, l]) => {
          //   console.log("l => ", l);
          if (["", null].includes(l)) {
            invalid++;
          }
        });
      });
    } else if (typeof value === "object") {
      if (_.isEmpty(value)) {
        return invalid++;
      }
      if (Object.values(value).filter((v) => v === "").length > 0) {
        invalid++;
      }
    } else {
      if (value === "") {
        invalid++;
      }
    }
  });
  return invalid;
};

export const checkInflationsPeriod = (inflations) => {
  //For each row of CapexTable, power|capacity[i] needs to be superior to power|capacity[i-1]
  //This blocks tech submit if not the case
  for (let i = 0; i < inflations.length; i++) {
    if (i !== 0) {
      if (+inflations[i]?.period <= +inflations[i - 1]?.period) {
        return false;
      }
    }
  }
  return true;
};

export const checkGrowthYear = (rate) => {
  //For each row of CapexTable, power|capacity[i] needs to be superior to power|capacity[i-1]
  //This blocks tech submit if not the case
  for (let i = 0; i < rate.length; i++) {
    if (i !== 0) {
      if (+rate[i]?.year <= +rate[i - 1]?.year) {
        return false;
      }
    }
  }
  return true;
};

export const checkCosts = (costs) => {
  //For each row of CapexTable, power|capacity[i] needs to be superior to power|capacity[i-1]
  //This blocks tech submit if not the case
  for (let i = 0; i < costs.length; i++) {
    if (i !== 0) {
      if (+costs[i]?.power < +costs[i - 1]?.power) {
        return false;
      } else if (+costs[i]?.capacity < +costs[i - 1]?.capacity) {
        return false;
      }
    }
  }
  return true;
};

export const exportJSONDataToTxt = (data, name) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data, null, "\t")
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = `${name}.txt`;
  link.click();
};

export const copyToClipboard = (element) =>
  navigator.clipboard.writeText(element.target.innerHTML.trim());

export const projectCostAndApplyInflation = (
  cost,
  projectLifespan,
  actualizationRate,
  subventionDuration
) => {
  const projectedCost = [];
  for (let currentYear = 0; currentYear < projectLifespan; currentYear++) {
    //In case of PV, if a subvention duration is provided
    //currentYear's subvention equan optim_params's subvention
    //only if currentYear < subventionDuration
    if (subventionDuration) {
      if (currentYear < subventionDuration) {
        projectedCost.push(cost);
        continue;
      }
      projectedCost.push(0);
      continue;
    }
    //In case tech has no subvention key, cost === null
    if (cost !== null) {
      projectedCost.push(
        cost / Math.pow(1 + actualizationRate / 100, currentYear)
      );
    } else {
      projectedCost.push(0);
    }
  }
  return projectedCost;
};

export const getDiffBetweenTwoDatesInUnit = (a, b, unit = "day") =>
  a.diff(b, unit) + 1;

export const getCookie = (cName) => {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

export const getCurrencySymbolFromISOCode = (isoCode) => {
  if (!isoCode) return "€";
  switch (isoCode) {
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "USD":
      return "$";
    case "XPF":
      return "XPF";
    default:
      return isoCode;
  }
};

export const makeItArray = (args) => (Array.isArray(args) ? args : [args]);

export const currenciesToFilter = ["USD", "EUR", "CHF", "GBP", "XPF"];

///////////////////////////////////// FOR C & I \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

export function clearLocalStorage() {
  const items = [
    "TotalEnergy",
    "ppaGraph",
    "Opex",
    "TechSummary",
    "AutoProd",
    "Van",
    "OptimizedSubP",
    "LineBalanceSheet",
    "Backup",
    "FreeCashFlow",
    "EnergyBalance",
    "UnitCost",
    "CostPerMonth",
    "TotalCost",
    "MonotoneOfPower",
    "YearlyBill",
    "DailyProfile",
    "Histogram",
    "CO2",
    "LoadCurve",
    "PV_EnergeticMix",
    "PV2_EnergeticMix",
    "WT_EnergeticMix",
    "Grid_EnergeticMix",
  ];
  items.forEach((i) => localStorage.removeItem(i));
}

export const scrollToTop = () =>
  window.scrollTo({ top: 0, behavior: "smooth" });

export const scenarioIsACC = (scen) =>
  scen && typeof scen?.configSite?.rateOfGrowth === "undefined";
