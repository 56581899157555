import React, { useState, useEffect, useContext, useRef } from "react";
import LocaleContext from "../../context/LocaleContext";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import TUTORIAL_VALUES from "../../constants/TutorialValues";

const TutorialContainer = ({ showTutorial, currentPage, closeTutorial }) => {
  const { i18n, manualURL: url } = useContext(LocaleContext);
  const [content, setContent] = useState(TUTORIAL_VALUES(currentPage, url));
  const tutorialRef = useRef(null);

  useEffect(() => {
    setContent(TUTORIAL_VALUES(currentPage, url));
  }, [currentPage, url]);
  const options = {
    nextLabel: i18n.t("next"),
    prevLabel: i18n.t("previous"),
    doneLabel: "OK",
  };
  const handleChange = (nextStep) => {
    if (currentPage === "/optimization") {
      const editorIsOn = document.getElementsByClassName(
        "create-scenario-wrapper"
      ).length;
      if (!editorIsOn) {
        //if in list of scenarios
        if (nextStep !== 0) {
          tutorialRef.current.introJs.exit();
        }
      } else if (editorIsOn) {
        //if in scenario editor
        if (nextStep === 0) {
          tutorialRef.current.introJs.nextStep();
        }
      }
    }
  };
  return (
    <Steps
      steps={content}
      enabled={showTutorial}
      initialStep={0}
      onExit={closeTutorial}
      options={options}
      ref={tutorialRef}
      onAfterChange={handleChange}
    />
  );
};

export { TutorialContainer };
