import React from "react";
import { Columns, Button } from "react-bulma-components/full";
import SiteConstant from "../../constants/Site";
import "./EditorSiteForm.scss";
import LocaleContext from "../../context/LocaleContext";

export default class Step0 extends React.Component {
  static contextType = LocaleContext;

  render() {
    const { setType, handleNextStep } = this.props;
    const { Translation } = this.context;
    return (
      <div className="create-site-wrapper container">
        <div className="title is-4 has-text-centered">
          <div className="subtitle is-uppercase">
            <Translation>
              {(t) => `${t("step")} 0 ${t("outOfPreposition")} 3`}
            </Translation>
          </div>
          <Translation>{(t) => t("siteInfo")}</Translation>
        </div>
        <Columns className="has-margin-vertical step0">
          <Columns.Column size={6} offset={3}>
            <Button
              color="primary"
              onClick={() => {
                setType(SiteConstant.type.CONSUMER);
                handleNextStep();
              }}
            >
              <Translation>{(t) => t("selfConsumptionSite")}</Translation>
              <i className="fa fa-chevron-right"></i>
            </Button>
          </Columns.Column>
          <Columns.Column size={6} offset={3}>
            <Button
              color="primary"
              onClick={() => {
                setType(SiteConstant.type.PRODUCER);
                handleNextStep();
              }}
            >
              <Translation>{(t) => t("supplierSite")}</Translation>{" "}
              <i className="fa fa-chevron-right"></i>
            </Button>
          </Columns.Column>
        </Columns>
      </div>
    );
  }
}
