import { db, storage } from "./../fire";

import Firebase from "firebase";
import to from "await-to-js";
import moment from "moment";
import AuthService from "./Auth";
import ScenarioService from "./Scenario";
import AuthConstants from "./../constants/Auth";
import DatabaseConstants, { COLLECTION_SITES } from "./../constants/Database";
import Axios from "axios";
import Papa from "papaparse";
import SiteConstants from "../constants/Site";
import mime from "mime-types";

class SiteService {
  constructor() {
    this.storageBaseReport = "/reports";
  }

  getById = (siteId) =>
    db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId)
      .get()
      .then((doc) => (doc.exists ? { id: doc.id, ...doc.data() } : null));

  getCollectiveSiteById = (siteId) =>
    db
      .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
      .doc(siteId)
      .get()
      .then((doc) => (doc.exists ? { id: doc.id, ...doc.data() } : null));

  add = (data) => {
    const refSite = db.collection(DatabaseConstants.COLLECTION_SITES).doc();
    data.user = data.user ? data.user : AuthService.getUserId();
    data.id = refSite.id;
    data._createdAt = moment(Date.now()).format();
    data.initialOwner = data.user;
    if (data.score) delete data.score;
    return refSite.set(data);
  };

  isCollectiveSite = (siteId) => {
    return db
      .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
      .doc(siteId)
      .get()
      .then(function (doc) {
        return doc.exists;
      })
      .catch(function (error) {
        if (error.code === "permission-denied") return false;
      });
  };

  /**
   * We want to keep track of the site's change in data source (PRM/CSV/INDEX...)
   * We want to keep track of the last 5 changes (SITE_MAX_DATA_SOURCE_HISTORY_LENGTH)
   */
  incrementSiteDataSourceHistory = async (siteData, newSource) => {
    const siteRef = db
      .collection(this.getCollectionFromSiteType(siteData))
      .doc(siteData.id);
    let dataSourceHistory = [];
    let newSourceObject = {
      source: newSource,
      date: moment().format("DD/MM/YYYY HH:mm"),
    };
    if (siteData.dataSourceHistory) {
      if (
        siteData.dataSourceHistory.length ===
        SiteConstants.SITE_MAX_DATA_SOURCE_HISTORY_LENGTH
      ) {
        dataSourceHistory = [
          newSourceObject,
          ...siteData.dataSourceHistory.slice(
            0,
            SiteConstants.SITE_MAX_DATA_SOURCE_HISTORY_LENGTH - 1
          ),
        ];
      } else {
        dataSourceHistory = [...siteData.dataSourceHistory];
        dataSourceHistory.unshift(newSourceObject);
      }
    } else {
      dataSourceHistory.unshift(newSourceObject);
    }
    console.log("updating data source history");
    siteRef.update({ ...siteData, dataSourceHistory });
    return dataSourceHistory;
  };

  getCollectionFromSiteType = ({ type }) => {
    switch (type) {
      case SiteConstants.type.CONSUMER_PLUS:
        return DatabaseConstants.COLLECTION_COLLECTIVE_SITES;
      default:
        return DatabaseConstants.COLLECTION_SITES;
    }
  };

  update = (siteId, siteData, recordModification = true) => {
    const siteRef = db
      .collection(this.getCollectionFromSiteType(siteData))
      .doc(siteId);
    if (recordModification)
      siteData._lastModified = moment(Date.now()).format();
    if (siteData.score) delete siteData.score;
    return siteRef.update(siteData);
  };

  setFaltyPrm = (siteId, faltyPrm) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);

    return siteRef.set({ faltyPrm }, { merge: true });
  };

  setGraphComment = (siteData, { graphName, comment }) => {
    const siteRef = db
      .collection(this.getCollectionFromSiteType(siteData))
      .doc(siteData.id);

    return siteRef.set({ comments: { [graphName]: comment } }, { merge: true });
  };

  updateEnedisNumber = (siteId, enedisNumber) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);

    return siteRef.set({ enedisNumber }, { merge: true });
  };

  updateKey = (siteId, key, value) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);

    return siteRef.set({ [key]: value }, { merge: true });
  };

  hasBeenAnalyzed = (siteId, hasBeenAnalyzed = true, analysisId = null) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);

    return siteRef.set({ hasBeenAnalyzed }, { merge: true });
  };

  transferToUser = async (siteId, user) => {
    let err = null;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);

    [err] = await to(docRef.set({ user }, { merge: true }));
    if (err) {
      console.log("err", err);
      return Promise.reject(err);
    }

    return Promise.resolve();
  };

  fullTransferToUser = async (siteId, user) => {
    let err = null;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);

    [err] = await to(docRef.set({ user, initialOwner: user }, { merge: true }));
    if (err) {
      console.log("err", err);
      return Promise.reject(err);
    }

    return Promise.resolve();
  };

  addTotalCost = (siteId, totalCost) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);
    return siteRef.update({ totalCost });
  };
  addTotalEnergy = (siteId, totalEnergy) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);
    return siteRef.update({ totalEnergy });
  };

  remove = (siteId, type = SiteConstants.type.CONSUMER) =>
    db
      .collection(this.getCollectionFromSiteType({ type }))
      .doc(siteId)
      .delete();

  /**
   * Upload l'historique de consommation (fichier au format .csv)
   *
   * @param {*} blob Fichier CSV sur la machine de l'utilisateur
   * @param {*} onProgress Fonction appelée durant la progression de l'upload
   * @return {string} URL du fichier CSV (retourner via une Promise)
   */

  uploadReport = async (
    blob = null,
    idSite,

    onStateChange = () => {}
  ) => {
    return new Promise((resolve, reject) => {
      const fileRef = storage.ref(`Sites/${idSite}/ConsoData.csv`);

      const uploadTask = fileRef.put(blob);

      if (blob == null) return reject(new Error("Blob is null"));
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url))
      );
    });
  };

  uploadConsentFile = async (
    blob = null,
    site,

    onStateChange = () => {}
  ) => {
    // get the file extension from the file MIME type
    let extension = mime.extension(blob.type);

    // if no extension was found
    if (extension === false) {
      // try getting the extension from the file name
      try {
        extension = blob.name.match(/\.[0-9a-z]+$/i)[0].substring(1);
      } catch {
        // otherwise just use ".extension_not_found" as temporary extension
        extension = "extension_not_found";
      }
    }

    return new Promise((resolve, reject) => {
      const path = site.collectiveSiteId
        ? `Sites/${site.collectiveSiteId}/Sites/${site.id}/Consent.${extension}`
        : `Sites/${site.id}/Consent.${extension}`;
      const fileRef = storage.ref(path);

      const uploadTask = fileRef.put(blob);

      if (blob == null) return reject(new Error("Blob is null"));
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            this.updateKey(site.id, "consentFileUrl", url);
            this.updateKey(site.id, "hasConsentData", true);
            return resolve(url);
          })
      );
    });
  };

  listAllSitesInStorage = async () => {
    const listRef = storage.ref(`/Sites`);
    console.log({ listRef });
    return listRef
      .listAll()
      .then((res) => res.prefixes.map((p) => p.location.path_.split("/")[1]))
      .catch((err) => console.log({ err }));
  };

  listAllFiles = async (siteId) => {
    const listRef = storage.ref(`Sites/${siteId}`);
    return listRef
      .listAll()
      .then((res) => {
        res.items.map((item) => item.location.path_.split("/")[2]);
        return res;
      })
      .catch((err) => console.log({ err }));
  };

  listAllSubsitesFolders = async (collectiveSiteId) => {
    const listRef = storage.ref(`Sites/${collectiveSiteId}/Sites`);
    return listRef
      .listAll()
      .then((res) => {
        // res.items.map((item) => item.location.path_.split("/")[2]);
        return res.prefixes.map((prefix) => prefix.location.path_ + "/");
      })
      .catch((err) => console.log({ err }));
  };

  deleteFolderContents = async (path) => {
    const ref = storage.ref(path);
    console.log("deleting : ", path);
    ref
      .listAll()
      .then((dir) => {
        dir.items.forEach((fileRef) => {
          this.deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach((folderRef) => {
          this.deleteFolderContents(folderRef.fullPath);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async deleteFile(pathToFile, fileName) {
    const ref = storage.ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete();
  }

  deleteReport = async (siteId) => {
    const file = "ConsoData.csv";
    let desertRef = storage.ref(`Sites/${siteId}/${file}`);
    desertRef.delete().catch((e) => e);
    this.update(siteId, {
      reportURL: "",
      _lastUpdate: moment().format("DD/MM/YYYY HH:mm"),
    });
  };
  getUserSites = async (userId) => {
    let err,
      data = null;

    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_SITES)
        .where("user", "==", userId)
        .get()
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(
      data.docs.map((doc) => doc.data()).filter((doc) => !doc.collectiveSiteId)
    );
  };

  getSitesByInitialOwner = async (userId) => {
    let err,
      data = null;

    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_SITES)
        .where("initialOwner", "==", userId)
        .get()
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(
      data.docs.map((doc) => doc.data()).filter((doc) => !doc.collectiveSiteId)
    );
  };

  getSubordinatesSites = async (userId) => {
    let err,
      data = null;

    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_SITES)
        .where("initialOwner", "==", userId)
        .get()
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(
      data.docs.map((doc) => doc.data()).filter((doc) => !doc.collectiveSiteId)
    );
  };

  // getSiteReportFromURL = (url, delimiter = ";", headers = false) =>
  //   getCSV(url, { delimiter, headers });

  getSiteReportFromURL = async (
    url,
    header = false,
    delimiter = undefined,
    worker = true
  ) =>
    new Promise((resolve) =>
      Papa.parse(url, {
        download: true,
        delimiter,
        header,
        worker,
        complete: async (results) => {
          return resolve(results.data);
        },
      })
    ).catch((err) => console.log("ERREUR PARSE :", err));

  analyzeFormatReportData = async (
    reportData,
    isFromAnalysis = false,
    log = false
  ) => {
    let filtered = [...reportData];
    let key = null;

    filtered = filtered.filter((e) => e[0] !== "" && e[0] !== "");
    if (log) console.log({ filtered });
    return Promise.resolve(
      filtered.map((rowData) => {
        return {
          dateTime: moment(
            `${Object.values(rowData)[0]} ${Object.values(rowData)[1]}`,

            "DD-MM-YYYY HH:mm"
          ).format(),
          value:
            this.Wh2kWh(parseFloat(Object.values(rowData)[2]).toFixed(3)) || 0,
        };
      })
    );
  };

  updateSiteOptimizationResults = (siteId, optimizationResults) => {
    const refSite = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);

    return refSite.set({ optimizationResults }, { merge: true });
  };

  uploadEnedisProof = async (
    siteId,
    payload,
    string = null,
    onStateChange = () => {}
  ) => {
    let file = JSON.stringify(payload);
    let blob = new Blob([file], { type: "application/json" });
    return new Promise((resolve, reject) => {
      console.log(`Consents/${siteId}/Consentement.json`);
      const fileRef = storage.ref(`Consents/${siteId}/Consentement.json`);

      const uploadTask = fileRef.put(blob);
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url))
      );
    });
  };

  addPdfElements = async (
    name,
    string = null,
    idSite,
    type,
    idScen,
    onStateChange = () => {}
  ) => {
    return new Promise((resolve, reject) => {
      let fileRef = null;
      switch (type) {
        case "ANALYSE":
          fileRef = storage.ref(`Sites/${idSite}/Pdfs/Analyse/${name}`);
          break;
        case "GENERAL":
          fileRef = storage.ref(`Sites/${idSite}/Pdfs/General/${name}`);
          break;
        default:
          fileRef = storage.ref(
            `Sites/${idSite}/Pdfs/Optimisation/${idScen}/${name}`
          );
          break;
      }

      let uploadTask = fileRef.putString(string, "data_url");
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url))
      );
    });
  };

  getAll = async () => {
    const docRef = db.collection(DatabaseConstants.COLLECTION_SITES);
    const [err, sites] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (sites)
      return sites.docs
        .map((doc) => doc.data())
        .filter((doc) => !doc.collectiveSiteId);
    return null;
  };

  getAllSubSites = async () => {
    const docRef = db.collection(DatabaseConstants.COLLECTION_SITES);
    const [err, sites] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (sites)
      return sites.docs
        .map((doc) => doc.data())
        .filter((doc) => doc.collectiveSiteId);
    return null;
  };

  moveReport = async (
    blob = null,
    idSite,

    onStateChange = () => {}
  ) => {
    return new Promise((resolve, reject) => {
      const fileRef = storage.ref(`Sites/${idSite}/ConsoData.csv`);

      const uploadTask = fileRef.put(blob);

      if (blob == null) return reject(new Error("Blob is null"));
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url))
      );
    });
  };

  getBlobFromUrl = async (url) =>
    await Axios.get(url, {
      responseType: "blob",
    }).then((res) => res.data);

  updateReportUrl = async (siteId, value) => {
    let err = null;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_SITES)
      .doc(siteId);

    [err] = await to(docRef.set({ reportURL: value }, { merge: true }));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  };
  analyseCloud = async (site) => {
    return Axios.post(
      AuthConstants.URL_ANALYZE,
      {
        site,
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    )
      .then((res) => Promise.resolve(res))
      .catch((e) => Promise.reject(e));
  };

  onNewCSV = async (siteId, fileURL) => {
    this.updateKey(siteId, "automaticUpdateError", null);
    this.updateKey(siteId, "reportURL", fileURL);
  };

  onNewPRM = async (siteId) => {
    this.updateKey(siteId, "analysisId", "");
  };

  getMapUrl = async (siteId) => {
    let type = "Analyse";
    let basePath = `Sites/${siteId}/Pdfs/${type}/`;
    let url = null;
    url = await storage.ref(`${basePath}Map`).getDownloadURL();
    return url;
  };

  filePathFactory = (type, siteId, scenId = "") => {
    switch (type) {
      case "OPTIM":
        return `Sites/${siteId}/Pdfs/Optimisation/${scenId}/`;
      case "GENERAL":
        return `Sites/${siteId}/Pdfs/General/`;
      case "COLLECTIVE_ANALYSIS":
        return `Sites/${siteId}/Sites/`;
      case "ANALYSIS":
        return `Sites/${siteId}/`;

      default:
        return `Sites/${siteId}/Pdfs/Analyse/`;
    }
  };

  getFileUrl = async (type, siteId, scenId = "", payload) => {
    let basePath = this.filePathFactory(type, siteId, scenId);

    let array = [...payload];

    array = array.filter((p) => p.name !== "Map");
    array = Promise.all(
      array.map(async (e) => {
        return {
          ...e,
          url: await storage.ref(`${basePath}${e.name}`).getDownloadURL(),
        };
      })
    );

    return array;
  };

  deleteAnalysisFiles = async (siteId, collectiveSiteId = "") => {
    let basePath;
    let array = [{ name: "analyse.json" }, { name: "ConsoDataFiltered.csv" }];
    if (!collectiveSiteId) {
      basePath = this.filePathFactory("ANALYSIS", siteId);
      array = Promise.all(
        array.map(async (e) => {
          console.log("Deleting ... ", `${basePath}${e.name}`);
          return await this.deleteFile(basePath, e.name);
        })
      );
    } else {
      let subSites = await this.listAllSubsitesFolders(collectiveSiteId);
      subSites.forEach(async (subSite) => {
        await Promise.all(
          array.map(async (e) => {
            console.log("Deleting ... ", `${subSite}${e.name}`);
            return await this.deleteFile(subSite, e.name);
          })
        );
      });
    }

    return array;
  };

  deleteAllSites = async (listOfSites) => {
    const batch = db.batch();
    listOfSites.forEach((site) => {
      let siteRef = db.collection(COLLECTION_SITES).doc(site.id);
      batch.delete(siteRef);
    });
    return batch.commit();
  };

  batchSoftDelete = async (listOfSites) => {
    const batch = db.batch();
    await Promise.all(
      listOfSites.map(async (site) => {
        let siteRef = db.collection(COLLECTION_SITES).doc(site.id);
        const payload = {
          user: DatabaseConstants.TRASH_USER_ID,
        };
        if (site.collectiveSiteId) {
          payload.collectiveSiteId = Firebase.firestore.FieldValue.delete();
        }
        return batch.update(siteRef, payload);
      })
    );
    return batch.commit();
  };

  deleteSubSite = async (subSite, fromAdmin = false) => {
    console.log(`Deleting ${subSite.name}`);
    if (!fromAdmin) return this.batchSoftDelete([subSite]);
    //Delete files from storage
    await this.deleteFolderContents(
      `Sites/${subSite.collectiveSiteId}/Sites/${subSite.id}`
    );
    //Delete from DB
    await this.remove(subSite.id);
  };
}

export default new SiteService();
