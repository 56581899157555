import React, { memo, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import randomString from "randomstring";
import KEYS from "../../keys";

import { addPdfElement } from "../../store/pdf/store";
import _, { random, remove, size } from "lodash";
import { numberFormat } from "highcharts";

const OPTIONS = {
  zoomControl: true,
  fullscreenControl: true,
  mapTypeId: "hybrid",
};

const bootstrapURLKeys = {
  key: KEYS.MAPS,
  libraries: ["drawing", "geometry", "places"].join(","),
};

const Map = memo((props) => {
  const isDraw = useRef(props?.isDraw);
  const [refresh, setRefresh] = useState(false);
  const path = useRef(_.cloneDeep(props?.path) || []);
  const coordinates = useRef(
    props.isInResults === true || props.isInDefaultScenario === true
      ? [...props?.coordinates]
      : [...props?.coordinates()]
  );
  const mapSetting = useRef({});
  const isInResults = useRef(props?.isInResults);
  const polygonList = useRef([]);
  const settings = useRef(props?.settings);
  const area =
    props.isInResults === false ? useRef(props?.getMaxArea()) : useRef(0);

  // useEffect(() => {}, [coordinates.current]);

  useEffect(() => {
    isDraw.current = props.isDraw;
  }, [props.isDraw]);
  useEffect(() => {
    isInResults.current = props.isInResults;
  }, [props.isInResults]);
  useEffect(() => {
    settings.current = props.settings;
  }, [props.settings]);

  // useEffect(() => {
  //   coordinates.current = [...props.coordinates];
  // }, [props.coordinates]);
  // useEffect(() => {
  //   isInResults.current = props.isInResults;
  // }, [props.isInResults]);

  function generatePolygonPv(size, maps, mainPolygon, map) {
    const width = size.width;
    let listOfPanels = [];
    let zoneArea = 0;
    let areaOfPanel = width * size.lng;
    const length = size.lng * Math.cos((size.tilt * Math.PI) / 180);
    if (width <= 0 || length <= 0) return;

    const bounds = new maps.LatLngBounds();
    const mainPolygonPath = mainPolygon.getPath();
    mainPolygonPath.forEach((point) => {
      bounds.extend(point);
    });
    // Calculer le centre du bounding box
    const currentCenter = {
      lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
      lng: (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2,
    };

    let maxDistance = 0;
    for (let i = 0; i < mainPolygonPath.getLength(); i++) {
      for (let j = i + 1; j < mainPolygonPath.getLength(); j++) {
        const distance = maps.geometry.spherical.computeDistanceBetween(
          mainPolygonPath.getAt(i),
          mainPolygonPath.getAt(j)
        );
        if (distance > maxDistance) {
          maxDistance = distance;
        }
      }
    }
    // Calculer le carré englobant
    const radius = maxDistance / Math.sqrt(2);
    const leftTop = maps.geometry.spherical.computeOffset(
      currentCenter,
      radius,
      315 + Number.parseFloat(size.orientation)
    );
    const rightTop = maps.geometry.spherical.computeOffset(
      currentCenter,
      radius,
      45 + Number.parseFloat(size.orientation)
    );
    const leftBottom = maps.geometry.spherical.computeOffset(
      currentCenter,
      radius,
      225 + Number.parseFloat(size.orientation)
    );
    const rightBottom = maps.geometry.spherical.computeOffset(
      currentCenter,
      radius,
      135 + Number.parseFloat(size.orientation)
    );
    const pathBig = [leftTop, rightTop, rightBottom, leftBottom];
    const polygonBig = new maps.Polygon();
    polygonBig.setOptions({
      fillColor: "green",
      strokeColor: "green",
      clickable: true,
    });

    polygonBig.setPath(pathBig);
    // polygonBig.setMap(map);
    const maxPossibleI = Math.ceil(
      maps.geometry.spherical.computeDistanceBetween(rightTop, rightBottom) /
        size.width
    );
    let maxPossibleJ =
      maps.geometry.spherical.computeDistanceBetween(rightTop, rightBottom) /
      length;
    // maxPossibleJ = Math.ceil(maxPossibleJ + maxPossibleJ * size.interdistance);
    // return;
    const leftTopLat = leftTop.lat();
    const leftTopLng = leftTop.lng();

    let panelPathList = [];
    let panelPathObject = {};
    let xy1 = maps.geometry.spherical.computeOffset(
      { lng: leftTopLng, lat: leftTopLat },
      length,
      0
    );
    // { lng: leftTopLng, lat: leftTopLat };
    let xy2 = null;
    let xy3 = null;
    let xy4 = null;
    for (let j = 0; j < maxPossibleJ; j++) {
      xy1 = maps.geometry.spherical.computeOffset(
        { lat: leftTopLat, lng: leftTopLng },
        length * j + size.interdistance * j,
        180 + Number.parseFloat(size.orientation)
      );

      for (let i = 0; i < maxPossibleI; i++) {
        xy2 = maps.geometry.spherical.computeOffset(
          { lng: xy1.lng(), lat: xy1.lat() },
          width,
          90 + Number.parseFloat(size.orientation)
        );

        xy3 = maps.geometry.spherical.computeOffset(
          { lng: xy2.lng(), lat: xy2.lat() },
          length,
          180 + Number.parseFloat(size.orientation)
        );

        xy4 = maps.geometry.spherical.computeOffset(
          { lng: xy3.lng(), lat: xy3.lat() },
          width,
          270 + Number.parseFloat(size.orientation)
        );

        const mainPanelPath = [
          { lng: xy1.lng(), lat: xy1.lat() },
          { lng: xy2.lng(), lat: xy2.lat() },
          { lng: xy3.lng(), lat: xy3.lat() },
          { lng: xy4.lng(), lat: xy4.lat() },
        ];
        xy1 = xy2;
        const polygon = new maps.Polygon();
        polygon.setPath(mainPanelPath);
        polygon.setOptions({
          fillColor: "blue",
          strokeColor: "black",
          strokeWeight: 0.4,
          clickable: true,
          zIndex: 2,
        });
        if (polygonIsOnAnotherPolygon(polygon, mainPolygon, maps) === true) {
          polygon.setMap(map);
          zoneArea = zoneArea + areaOfPanel;
          panelPathList.push(mainPanelPath);
          listOfPanels.push(polygon);
          polygon.addListener("rightclick", (e) => {
            coordinates.current = [map.center.lat(), map.center.lng()];

            polygon.setMap(null);
            _.map(path.current, (p) => {
              _.map(p.panels, (panel, key) => {
                if (_.isEqual(panel, mainPanelPath)) {
                  p.zoneArea = p.zoneArea - areaOfPanel;
                  delete p.panels[key];
                }
              });
            });
            handleUpdate();
          });
        }
      }
    }
    for (let i = 0; i < panelPathList.length; i++) {
      panelPathObject[i] = panelPathList[i];
    }
    polygonList.current.push({ listOfPanels, mainPolygon });
    return { panels: panelPathObject, zoneArea };
  }

  function polygonIsOnAnotherPolygon(polygon, polygonBig, maps) {
    let isOn = true;
    polygon
      .getPath()
      .getArray()
      .map((p2) => {
        if (maps.geometry.poly.containsLocation(p2, polygonBig) === false) {
          isOn = false;
        }
      });
    return isOn;
  }

  const handleChange = (map) => {
    if (isInResults.current) {
      addPdfElement("Map", {
        center: { lat: map.center.lat, lng: map.center.lng },
        marker: { lat: map.center.lat, lng: map.center.lng },
        zoom: map.zoom,
        paths: path.current.map((p) => p.mainPanel),
        panels: path.current.flatMap((p) => _.map(p.panels, (panel) => panel)),
      });
    } else {
      coordinates.current = [map.center.lat, map.center.lng];
      props.setCoordinates([map.center.lat, map.center.lng]);
    }
  };

  const handleUpdate = async () => {
    let newArea = 0;
    _.map(path.current, (p) => {
      newArea = newArea + p.zoneArea;
    });
    area.current = Math.ceil(parseFloat(newArea) * 10) / 10;
    // props.triggerCompute(path.current, area.current);
    if (props.isCustomArea === false) {
      path.current.forEach((p) => {
        p.setting.share = (p.zoneArea / area.current) * 100;
        // let surfacePanel = p.setting.width * p.setting.lng;
        // let zoneArea = 0;
      });

      props.triggerCompute(path.current, area.current);
      return props.triggerCompute(path.current, area.current);
    } else {
      props.triggerCompute(path.current);
      return props.triggerCompute(path.current, props.getMaxArea());
    }
  };
  const handlePolygonComplete = (polygon, maps, map) => {
    coordinates.current = [map.center.lat(), map.center.lng()];
    const pathPolygon = polygon.getPath();
    const newPath = pathPolygon.getArray().map((e) => ({
      lat: e.lat(),
      lng: e.lng(),
    }));
    const lastSetting = _.findLastKey(props.settings, (setting) => {
      return setting;
    });

    if (_.size(path.current) > 0) {
      const newSetting = {
        width: props.settings[lastSetting].width,
        lng: props.settings[lastSetting].lng,
        tilt: props.settings[lastSetting].tilt,
        orientation: props.settings[lastSetting].orientation,
        interdistance: props.settings[lastSetting].interdistance,
        share: props.settings[lastSetting].share,
      };
      props.newSurfacePvRow(props.settings[lastSetting].id, newSetting);
    }

    const roof = {
      setting: settings.current[lastSetting],
      mainPanel: newPath,
      zoneArea: 0,
      panels: {},
    };
    path.current = [...path.current, roof];
    handleUpdate();
  };
  const showMap = (map, maps) => {
    if (props.isInResults !== true && _.size(props.settings) === 0) {
      props.newSurfacePvRow(randomString.generate(10), {
        width: 1,
        lng: 2,
        tilt: 15,
        orientation: 0,
        interdistance: 0,
        share:  0,
      });
    }
    if (path.current.length > 0 && props.isInResults !== true) {
      path.current.forEach((p, i) => {
        // detect older version
        if (_.size(p.content) > 0) {
          p.mainPanel = p.content;
          if (_.size(props.settings[i]) === 0)
            props.settings[i] = props.settings[_.size(props.settings) - 1];
          p.setting = props.settings[i];
          p.setting.width = 1;
          p.setting.lng = 2;
          p.setting.interdistance = 0;
          p.setting.share = 0;
          delete p.area;
          delete p.content;
        }
        const mainPolygon = new maps.Polygon();

        mainPolygon.setPath(p.mainPanel);

        mainPolygon.setOptions({
          strokeColor: "black",
          fillOpacity: 0,
          clickable: true,
        });

        mainPolygon.addListener("rightclick", (e) => {
          coordinates.current = [map.center.lat(), map.center.lng()];

          mainPolygon.setMap(null);
          _.map(polygonList.current, (p, key) => {
            if (p?.mainPolygon === mainPolygon) {
              p.listOfPanels.forEach((panel) => {
                panel.setMap(null);
              });
              delete polygonList.current[key];
            }
          });
          path.current = path.current.filter((path) => path !== p);
          props.removeSurfacePvRow(p.setting.id);
          handleUpdate();
        });

        mainPolygon.setMap(map);

        if (_.has(p.setting, "share") === true) {
          // delete p.setting.share;
        }
        
        if (
          _.isEqual(p.setting, props.settings[i]) === false ||
          (_.isEqual(p.setting, props.settings[i]) === true &&
            _.size(p.panels) === 0)
        ) {
          if (props.settings[i].width <= 0 || props.settings[i].lng <= 0)
            return;

          let result = generatePolygonPv(
            props.settings[i],
            maps,
            mainPolygon,
            map
          );

          p.panels = result.panels;
          p.zoneArea = result.zoneArea;
          p.setting = props.settings[i];
        } else {
          let panelList = [];
          _.map(p.panels, (panel, key) => {
            const newPanel = new maps.Polygon();
            newPanel.setPath(panel);
            newPanel.setOptions({
              fillColor: "blue",
              strokeColor: "black",
              strokeWeight: 0.4,
              clickable: true,
              zIndex: 2,
            });

            newPanel.setMap(map);
            panelList.push(newPanel);

            newPanel.addListener("rightclick", (e) => {
              coordinates.current = [map.center.lat(), map.center.lng()];

              newPanel.setMap(null);
              delete p.panels[key];
              let area = p.setting.width * p.setting.lng;
              p.zoneArea = p.zoneArea - area;
              handleUpdate();
            });
          });

          polygonList.current.push({ listOfPanels: panelList, mainPolygon });
        }
      });
      handleUpdate();
    }
    if (props.isInResults === true || props.isInDefaultScenario === true) {
      addPdfElement("Map", {
        center: { lat: coordinates.current[0], lng: coordinates.current[1] },
        marker: { lat: coordinates.current[0], lng: coordinates.current[1] },
        zoom: 18,
        paths: path.current.map((p) => p.mainPanel),
        panels: path.current.flatMap((p) => _.map(p.panels, (panel) => panel)),
      });
      path.current.forEach((p, i) => {
        const mainPolygon = new maps.Polygon();
        mainPolygon.setPath(p.mainPanel);
        mainPolygon.setOptions({
          strokeColor: "black",
          fillOpacity: 0,
          clickable: true,
        });
        mainPolygon.setMap(map);
        let panelList = [];
        _.map(p.panels, (panel, key) => {
          const newPanel = new maps.Polygon();
          newPanel.setPath(panel);
          newPanel.setOptions({
            fillColor: "blue",
            strokeColor: "black",
            strokeWeight: 0.4,
            clickable: true,
            zIndex: 2,
          });
          newPanel.setMap(map);
          panelList.push(newPanel);
        });
      });
    }
    // if (path.current[0]) {
    //   path.current[0].forEach((p) => {
    //     let surface = new maps.Polygon();
    //     surface.setPath(p.content);
    //     surface.setOptions({
    //       fillColor: "red",
    //       strokeColor: "red",
    //       clickable: true,
    //     });
    //     area.current = maps.geometry.spherical.computeArea(surface.getPath());
    //     surface.addListener("rightclick", (e) => {
    //       props.triggerCompute(-area.current, p.content);
    //       surface.setMap(null);
    //     });
    //     surface.addListener("click", (e) => {
    //       props.triggerCompute(-area.current, p.content);
    //       surface.setMap(null);
    //     });
    //     surface.setMap(map);
    //   });
    // }
    // to draw new polygons
    if (isDraw.current) {
      if (!maps.drawing) return;
      let draw = new maps.drawing.DrawingManager({
        drawingControlOptions: {
          drawingModes: ["polygon"],
        },
        drawingMode: "polygon",
        polygonOptions: {
          strokeColor: "black",
          fillOpacity: 0,
          clickable: true,
        },
      });

      let marker = new maps.Marker({
        animation: maps.Animation.DROP,
        clickable: false,
        position: { lat: coordinates.current[0], lng: coordinates.current[1] },
        visible: true,
        map,
      });

      map.setTilt(0);
      marker.setMap(map);

      maps.event.addListener(draw, "polygoncomplete", (polygon) => {
        coordinates.current = [map.center.lat(), map.center.lng()];

        // implement layout for polygon here
        draw.setDrawingMode(null);
        // if (_.size(props.settings) > 1) {
        //   const lastSetting = _.findLastKey(
        //     settings.current,
        //     (setting) => setting
        //   );
        //   props.newSurfacePvRow(
        //     props.settings[lastSetting].id,
        //     props.settings[lastSetting]
        //   );
        // }
        handlePolygonComplete(polygon, maps, map);
        polygon.setMap(map);
        draw.setDrawingMode("polygon");
        setRefresh(!refresh);
      });
      draw.setMap(map);
    } else {
      let marker = new maps.Marker({
        animation: maps.Animation.DROP,
        clickable: false,
        position: { lat: coordinates.current[0], lng: coordinates.current[1] },
        visible: true,
        map,
        draggable: true,
      });

      map.setCenter({
        lat: coordinates.current[0],
        lng: coordinates.current[1],
      });
      map.setZoom(18);
      map.setTilt(0);

      marker.addListener("mouseup", (e) => {
        coordinates.current = [map.center.lat(), map.center.lng()];

        let latLng = marker.getPosition();
        mapSetting.current = {
          lat: latLng.lat(),
          lng: latLng.lng(),
        };
        map.setCenter({ lat: latLng.lat(), lng: latLng.lng() });
        props.getCodeFromMarker({ lat: latLng.lat(), lng: latLng.lng() });
      });

      marker.setMap(map);
    }
  };
  return (
    <div
      className={`${isInResults.current ? "results-map" : ""}`}
      style={{
        height: "500px",
        width: isInResults.current ? "75%" : "100%",
        margin: "auto",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={bootstrapURLKeys}
        defaultCenter={{ lat: 46.616545, lng: 2.728726 }}
        defaultZoom={2000}
        center={{ lat: coordinates.current[0], lng: coordinates.current[1] }}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => showMap(map, maps)}
        options={OPTIONS}
        key={randomString.generate(10)}
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
});
Map.displayName = "Map";
export default Map;
