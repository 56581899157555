module.exports = {
  fr: {
    totalEnergyUsed: "Energie totale consommée",
    totalCost: "Coût total",
    unitCostAnalysis: "Coût final du kWh consommé",
    unitCostResults: "Coût final du kWh consommé",
    unitSavings: "Coût du kWh effacé",
    gridUnitCost: "Coût du kWh acheté au réseau",
    "LCOE PV": "LCOE Photovoltaïque",
    "LCOE PV2": "LCOE Photovoltaïque 2",
    "LCOE WT": "LCOE Eolien",
    "LCOE Electrolyse": "LCOE Electrolyse",
    "LCOE FuelCell": "LCOE Pile à combustible",
    "LCOE Gen": "LCOE Grp. Elec.",

    costPerMonth: "Facturation mensuelle",
    energyBalance: "Bilan énergétique mensuel par tranche horaire",

    loadCurve: "Courbe de puissances",
    distributionKey: "Clés de répartition pour la première année",
    dailyProfile: "Courbe de puissances journalière moyenne",
    monotonic: "Monotone des puissances actives soutirées",
    histogram: "Histogramme des puissances actives soutirées",
    npv: "Gains cumulés actualisés",
    npvAbbr: "Gains cumulés actualisés",
    lineBalanceSheetChart:
      "Projection des coûts (factures réseau - revenus + coûts d'exploitation + coûts financiers)",
    gainsLineBalanceSheetChart:
      "Projection des économies (Coût de référence - coût post projet)",
    gainsProjection:
      "Projection des gains  (revenus de vente - couts d'exploitation - couts financiers)",
    financialSynthesis: "Synthèse financière",
    techSummary: "Mix énergétique",
    objectives: "Objectifs",
    yearlyBill: "Coût total de la première année",
    opex: "Détails des coûts et des revenus",
    optimizedSubP: "Puissances souscrites optimisées",
    supplierOpex: "Détail des revenus et des dépenses",
    ppaGraph: "Couverture PPA",
    emmitedCO2: "CO2 émis",
    energeticMix: "Mix énergétique",
    consumerEnergyBalance: "Bilan énergétique mensuel",
  },
  en: {
    totalEnergyUsed: "Total energy",
    totalCost: "Total cost",
    unitCostAnalysis: "Unit cost",
    unitCostResults: "Unit cost",
    unitSavings: "Saved unit cost",
    gridUnitCost: "Grid unit cost",
    "LCOE PV": "LCOE Photovoltaic",
    "LCOE PV2": "LCOE Photovoltac 2",
    "LCOE WT": "LCOE Windturbine",
    "LCOE Electrolyse": "LCOE Electrolysis",
    "LCOE FuelCell": "LCOE Fuel cell",
    "LCOE Gen": "LCOE Generator",

    costPerMonth: "Monthly cost",
    energyBalance: "Monthly energy balance per time of use",

    loadCurve: "Power timeseries",
    distributionKey: "Distribution keys for the first year",
    dailyProfile: "Average daily load curve",
    monotonic: "Monotonic of active power values",
    histogram: "Histogram of active power values",
    npv: "Net Present Value",
    npvAbbr: "NPV",
    lineBalanceSheetChart:
      "Costs projection (grid bills - revenues + opex + financial costs)",
    gainsLineBalanceSheetChart:
      "Savins projection (Reference cost - post project cost)",
    gainsProjection:
      "Gains projection  (sales revenues - opex - financial expenditures)",

    financialSynthesis: "Financial Summary",
    techSummary: "Energy mix",
    objectives: "Objectives",
    yearlyBill: "Total cost of the first year",
    opex: "Total cost of the first year breakdown",
    optimizedSubP: "Optimized subscribed powers",
    supplierOpex: "Costs and revenues breakdown",
    ppaGraph: "PPA coverage",
    emmitedCO2: "CO2 emissions",
    energeticMix: "Energetic mix",
    consumerEnergyBalance: "Monthly energy balance",
  },
};
