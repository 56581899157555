export const asideMenuItems = {
  USERS_LIST: "USERS_LIST",
  MAILING: "MAILING",
  INFO: "INFO",
  DEVELOPPERS_LIST: "DEVELOPPERS_LIST",
  CLOUD_FUNCTIONS_TESTER: "cloud_functions_tester",
  DATAPROTECTION: "DATAPROTECTION",
  LEGAL: "LEGAL",
  INFOS: "INFOS",
  CREDENTIALS: "CREDENTIALS",
  CUSTOMIZATION: "CUSTOMIZATION",
  DEFAULT_SCENARIO: "DEFAULT_SCENARIO",
  DEFAULT_RATES: "DEFAULT_RATES",
  RESULT: "RESULT",
  SCENARIO: "SCENARIO",
  CREATE_CONSUMER_SCENARIO: "CREATE_CONSUMER_SCENARIO",
  EDIT_CONSUMER_SCENARIO: "EDIT_CONSUMER_SCENARIO",
  CREATE_PRODUCER_SCENARIO: "CREATE_PRODUCER_SCENARIO",
  EDIT_PRODUCER_SCENARIO: "EDIT_PRODUCER_SCENARIO",
  SITES: "SITES",
  CREATE_SITE: "CREATE_SITE",
  EDIT_SITE: "EDIT_SITE",
};

export const ADMIN_MENU = [
  {
    href: "#/admin",
    rendering: asideMenuItems.USERS_LIST,
    icon: "list",
    label: "Gestion des utilisateurs",
  },
  {
    href: "#/admin",
    rendering: asideMenuItems.DEVELOPPERS_LIST,
    icon: "list",
    label: "Gestion des développeurs",
  },
  {
    href: "#/admin",
    rendering: asideMenuItems.MAILING,
    icon: "envelope",
    label: "Mailing",
  },
  {
    href: "#/admin",
    rendering: asideMenuItems.INFO,
    icon: "info",
    label: "Barre d'info",
  },
  {
    href: "#/admin",
    rendering: asideMenuItems.CLOUD_FUNCTIONS_TESTER,
    icon: "cloud",
    label: "Testeur des fonctions cloud",
  },
];

export const MY_ACCOUNT_MENU = [
  {
    href: "javascript:void(0)",
    rendering: asideMenuItems.INFOS,
    icon: "pencil",
    label: "myContactDetails",
  },
  // {
  //   href: "javascript:void(0)",
  //   rendering: asideMenuItems.CREDENTIALS,
  //   icon: "lock",
  //   label: "myCredentials",
  // },
  // {
  //   href: "javascript:void(0)",
  //   rendering: asideMenuItems.CUSTOMIZATION,
  //   icon: "paint-brush",
  //   label: "myCustomization",
  // },
  {
    href: "javascript:void(0)",
    rendering: asideMenuItems.DEFAULT_SCENARIO,
    icon: "gears",
    label: "defaultScenarios",
  },
  {
    href: "javascript:void(0)",
    rendering: asideMenuItems.DEFAULT_RATES,
    icon: "balance-scale",
    label: "defaultRates",
  },
  {
    href: "javascript:void(0)",
    rendering: asideMenuItems.LEGAL,
    icon: "university",
    label: "termsOfService",
  },
];
