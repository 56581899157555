import React, { useContext, useState, useEffect } from "react";

import LocaleContext from "../../../context/LocaleContext";
import SiteConstants from "../../../constants/Site";
import { Button, Modal } from "react-bulma-components/full";
import EditorSiteForm from "../EditorSiteForm";
import { SiteListItem } from "../../Home/CollectiveSiteListItem";
import SiteService from "../../../services/Site";
import ScenarioService from "../../../services/Scenario";
import CollectiveSitesService from "../../../services/CollectiveSites";
import { CustomButton } from "../../CustomButton";
import { CustomButtonSecondary } from "../../CustomButtonSecondary";

const EditSiteFormModal = ({
  close,
  user,
  userDefaultRates,
  editMode,
  subSiteToEdit,
  collectiveSite,
  updateSubSite,
  deleteSubSite,
}) => {
  const handleSubmitSuccess = (site) => {
    updateSubSite(site);
    close();
  };
  return (
    <Modal
      show={true}
      onClose={close}
      className="collective-site-edit-site-form-modal"
    >
      <Modal.Card>
        <Modal.Card.Body>
          <EditorSiteForm
            isCollectiveSiteEditorMode={true}
            collectiveSite={collectiveSite}
            type={SiteConstants.type.CONSUMER}
            userType={user.type}
            onSubmitSuccess={handleSubmitSuccess}
            userDefaultRates={userDefaultRates}
            editMode={editMode}
            siteToEdit={subSiteToEdit}
            updateSubSite={updateSubSite}
            deleteSubSite={deleteSubSite}
            cancelEditor={close}
          />
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export function EditorCollectiveSiteForm(props) {
  const context = useContext(LocaleContext);
  const { Translation } = context;
  const [subSiteToEdit, setSubSiteToEdit] = useState(undefined);
  const [isShowEditSiteForm, setIsShowEditSiteForm] = useState(false);

  const { user, sites, setSites, isLoading } = props;

  // if subSite is not in state add it
  // else edit it in the state
  const updateSubSite = (subSite) => {
    if (!sites.find((site) => site.id === subSite.id)) {
      return setSites([...sites, subSite]);
    }
    setSites(
      sites.map((site) => {
        if (site.id === subSite.id) return subSite;
        return site;
      })
    );
  };

  const deleteSubSite = (site) => {
    // delete site from state
    setSites(sites.filter((subSite) => subSite.id !== site.id));
  };

  const editSite = (site) => {
    setSubSiteToEdit(site);
    setIsShowEditSiteForm(true);
  };

  const deletesubSiteFromDb = async (subSite) => {
    //We need to fetch collectiveSite to remove the subsite from its sites list
    const collectiveSite = await SiteService.getCollectiveSiteById(
      subSite.collectiveSiteId
    );
    await SiteService.update(collectiveSite.id, {
      ...collectiveSite,
      sites: collectiveSite.sites.filter((site) => subSite.id !== site),
    });
    //We transfer the subsite to trash account as an individual site
    await SiteService.deleteSubSite(subSite);
    //We remove it form scenarios
    await ScenarioService.updateScenariosAfterSiteDeletion(subSite);
  };

  const close = () => {
    setSubSiteToEdit(undefined);
    setIsShowEditSiteForm(false);
  };

  return (
    <>
      {isShowEditSiteForm && (
        <EditSiteFormModal
          close={close}
          user={user}
          editMode={!!subSiteToEdit}
          subSiteToEdit={subSiteToEdit}
          updateSubSite={updateSubSite}
          deleteSubSite={deleteSubSite}
          collectiveSite={props.collectiveSite}
        />
      )}
      <div className="create-site-wrapper container">
        <div
          className="title is-4 has-text-centered"
          style={{ marginBottom: "0", width: "80%", margin: "auto" }}
        >
          <div className="subtitle is-uppercase">
            <Translation>
              {(t) => `${t("step")} 2 ${t("outOfPreposition")} 2`}
            </Translation>
          </div>
        </div>
        {/** list all the sites of the collective site */}
        <div className="collective-site-editor">
          {sites.map((site, i) => (
            <SiteListItem
              key={i}
              site={site}
              handleShowEditSite={editSite}
              handleDeletesubSite={(subSite) => {
                deletesubSiteFromDb(subSite);
                deleteSubSite(subSite);
              }}
              isCollectiveSiteEditorMode={true}
              fromEditor
            />
          ))}
        </div>
        {sites.length >= 0 && (
          <Button
            fullwidth
            color="info"
            className="add-row-button"
            onClick={() => setIsShowEditSiteForm(true)}
            disabled={isLoading}
            loading={isLoading}
          >
            {sites.length === 0 ? (
              <Translation>{(t) => t("addMyFirstCounter")}</Translation>
            ) : (
              <Translation>{(t) => t("addAnotherCounter")}</Translation>
            )}
          </Button>
        )}
        <div className="is-centered buttons has-addons">
          <CustomButton
            background="$lightgray"
            color="black"
            className="cancel-button"
            border="solid black 1px"
            hoverBackgroundColor="#C0C0C0"
            onClick={props.cancelEditor}
            loading={isLoading}
            disabled={isLoading}
          >
            <Translation>{(t) => t("cancel")}</Translation>
          </CustomButton>
          <CustomButtonSecondary
            onClick={props.handlePrevStep}
            loading={isLoading}
            disabled={isLoading}
          >
            <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />{" "}
            <Translation>{(t) => `${t("previous")}`}</Translation>
          </CustomButtonSecondary>
          <CustomButton
            onClick={props.onSubmitSuccess}
            loading={isLoading}
            disabled={sites.length < 1 || isLoading}
          >
            <Translation>{(t) => `${t("save")}`}</Translation>{" "}
          </CustomButton>
        </div>
      </div>
    </>
  );
}
