import React from "react";
import styled from "styled-components";
import { CustomHoverPopUp } from "../../../CustomHoverPopUp";
import { CustomButtonWithIcon } from "../../../CustomButtonWithIcon";
const SiteCard = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  img {
    max-width: 150px;
  }
  &.div1 {
    grid-area: 1 / 1 / 2 / 2;
    grid-column: span ${(props) => (props.shouldBeCentered ? 2 : 1)};
  }
  &.div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  &.div3 {
    grid-area: 2 / 1 / 3 / 2;
    grid-column: span ${(props) => (props.shouldBeCentered ? 2 : 1)};
  }
  &.div4 {
    grid-area: 2 / 2 / 3 / 3;
  }
  transition: all 0.2sec ease-in-out;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: -10%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    visibility: hidden;
  }
  &:hover {
    backdrop-filter: blur(5px);
    transition: visibility 0.2s ease-in-out;
    button {
      visibility: visible;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;

  justify-content: center;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.2sec ease-in-out;
  }
`;

const TextContainer = styled.p`
  color: #0a3b58;
  font-weight: 600;
  margin-top: 15%;
`;

export const SiteTypeCard = ({
  choice,
  position,
  numberOfChoices,
  buttonTrigger = null,
}) => {
  return (
    <SiteCard
      className={`div${position + 1}`}
      shouldBeCentered={position + 1 === numberOfChoices}
    >
      <CustomHoverPopUp
        position={"bottom center"}
        title={choice.name}
        content={choice?.description}
      >
        <ImageContainer onClick={choice.action}>
          <img src={choice.img} />
        </ImageContainer>
        {buttonTrigger && (
          <ButtonContainer>
            <CustomButtonWithIcon
              icon="lock"
              round
              onClick={buttonTrigger}
              type="success"
              scaleOnHover
            >
              Passer à la version supérieure
            </CustomButtonWithIcon>
          </ButtonContainer>
        )}
      </CustomHoverPopUp>
      <TextContainer>{choice.name}</TextContainer>
    </SiteCard>
  );
};
